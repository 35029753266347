import React from "react";

const LogoutIcon = ({mainColor}) => {
  
  const textColor = `text-`+mainColor || `text-white`;
  const borderColor = `border-t-`+mainColor || `border-t-white`;  

  return (
    
                
    <div className="text-center mx-auto w-6 h-6 stroke-red-500 text-white">
    
    <svg viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
      <path d="M15 16.5V19C15 20.1046 14.1046 21 13 21H6C4.89543 21 4 20.1046 4 19V5C4 3.89543 4.89543 3 6 3H13C14.1046 3 15 3.89543 15 5V8.0625M11 12H21M21 12L18.5 9.5M21 12L18.5 14.5"  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
                
    </div>       
              
             
           
  );
};

export default LogoutIcon;