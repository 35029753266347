import React from "react";

const SignupIcon = ({mainColor}) => {
  
  const textColor = `text-`+mainColor || `text-white`;
  const borderColor = `border-t-`+mainColor || `border-t-white`;  

  return (
    
                
    <div className="text-center mx-auto w-6 h-6 text-white">
    
    <svg fill="#FFFFFF" 
	 viewBox="0 0 500.002 500.002" >

		<path d="M493.146,23.427L476.574,6.851c-9.14-9.132-24.012-9.136-33.144,0L35.846,414.443c-0.032,0.032-0.048,0.068-0.08,0.1
			c-0.216,0.22-0.4,0.468-0.584,0.716c-0.132,0.172-0.276,0.336-0.392,0.52c-0.132,0.196-0.228,0.416-0.332,0.624
			c-0.136,0.26-0.276,0.516-0.38,0.784c-0.016,0.044-0.04,0.076-0.056,0.124l-21.28,58.9L2.29,486.666
			c-3.052,3.052-3.052,8,0,11.048c1.528,1.528,3.524,2.288,5.524,2.288s4.004-0.76,5.524-2.288l10.452-10.456l58.9-21.276
			c0.048-0.016,0.084-0.04,0.124-0.056c0.268-0.108,0.524-0.244,0.784-0.38c0.212-0.108,0.424-0.2,0.624-0.332
			c0.184-0.116,0.352-0.26,0.524-0.4c0.248-0.18,0.492-0.364,0.716-0.58c0.032-0.032,0.068-0.052,0.096-0.084L462.406,87.306
			l5.636,5.64l-137.84,137.852c-3.056,3.052-3.056,7.996,0,11.048c1.524,1.524,3.524,2.288,5.524,2.288
			c1.996,0,3.996-0.764,5.524-2.288L484.626,98.47c1.464-1.464,2.288-3.452,2.288-5.524c0-2.072-0.824-4.06-2.288-5.524
			l-11.172-11.168l19.692-19.692C502.286,47.435,502.286,32.567,493.146,23.427z"/>
	
</svg>
                
    </div>       
              
             
           
  );
};

export default SignupIcon;